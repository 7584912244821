<template>
  <v-container>
    <base-material-card
      color="indigo"
      icon="mdi-home-search"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Фільтр
        </div>
      </template>
      <v-row
      >
        <v-col cols="12" md="3">
          <v-text-field
            v-model="filters.title"
            label="Назва"
            clearable
            @input="applyFilters"
            @click:clear="getTableData"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="filters.min_price"
            label="Мінімальна ціна"
            clearable
            @input="applyFilters"
            @click:clear="getTableData"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="filters.max_price"
            label="Максимальна ціна"
            clearable
            @input="applyFilters"
            @click:clear="getTableData"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="filters.categories"
            :items="categories"
            label="Категорії"
            :item-text="category"
            item-value="id"
            multiple
            persistent-hint
            @input="getTableData"
            @click:clear="getTableData"
          ></v-select>
        </v-col>

      </v-row>

      <v-divider class="mt-3 mb-3"/>

      <v-list-group
        :value="true"
        v-for="categoryGroup in tableData"
        :key="categoryGroup.id"
        class="mb-5"
        light
      >

        <template v-slot:activator>
        <v-list-item-title class="font-weight-bold text-h4 mb-2 rounded" style="color: #1976D2; opacity: 0.8" >
          {{ categoryGroup.title }}
        </v-list-item-title>
        </template>

        <v-list-item-group
          v-for="priceGroup in categoryGroup.apartments"
          :key="priceGroup.price_range"
          class="mb-5 ml-4"
          :class="{'ml-8': !$vuetify.breakpoint.mobile}"
          light
          sub-group
        >
          <v-list-item-title class="font-weight-bold text-h5" style="color: black">
            {{ priceGroup.price_range }}
          </v-list-item-title>

          <v-list-item
          v-for="(child, i) in priceGroup.apartments"
          :key="`child_${child.title + i}`"
          :class="{'pl-8': !$vuetify.breakpoint.mobile}"
        >
          <v-list-item-title v-html="child.title" style="white-space: normal">
          </v-list-item-title>
          <v-icon
            right
            @click="selectItem(child.id)"
          >
            mdi-eye
          </v-icon>
        </v-list-item>
        </v-list-item-group>
      </v-list-group>
    </base-material-card>

    <ItemModal
      :modal-is-show="modalIsShow"
      :item="currentItem"
      @closeModal="toggleModal"
      @showFullImage="toggleShowImage"
      v-if="currentItem"
    />

    <ImageModal
      v-if="currentItem"
      :item="currentItem"
      :modal-is-show="showFullImage"
      :image-index="imageIndex"
      @toggleModal="toggleShowImage"
    />
  </v-container>
</template>
<script>
import draggable from 'vuedraggable';
import ItemModal from '../category/ItemModal.vue';
import ImageModal from "../components/apartment/ImageModal.vue";
import apartment from "../../../services/apartment";
import category from "../../../services/category";

export default {
  name: 'CategoryPage',
  components: {
    ItemModal,
    draggable,
    ImageModal
  },
  data() {
    return {
      showFullImage: false,
      imageIndex: 0,
      modalIsShow: false,
      editMode: false,
      tableData: [],
      categories: [],
      loading: true,
      renderListKey: 0,
      activeSubCategory: 0,
      search: '',
      currentItem: null,
      filters: {
        title: '',
        min_price: '',
        max_price: '',
        categories: []
      },
      filtersTimer: 0
    }
  },
  watch: {
    tableData: {
      handler() {
        this.renderListKey = this.renderListKey + 1;
      },
      deep: true
    }
  },
  mounted() {
    this.getTableData();
    this.getCategories();
  },

  methods: {
    applyFilters() {
      clearTimeout(this.filtersTimer)

      this.filtersTimer = setTimeout(() => {
        this.getTableData()
      }, 600)
    },

    getTableData() {
      apartment.get(this.filters)
        .then((res) => {
          if (res) this.tableData = res.data.data
        })
    },
    getCategories() {
      category.get()
        .then((res) => {
          if (res) this.categories = res.data.data
        })
    },

    toggleModal() {
      this.modalIsShow = !this.modalIsShow;
      this.showFullImage = false
    },

    toggleShowImage(index) {
      this.imageIndex = index
      this.showFullImage = true
    },

    selectItem(itemId) {
      apartment.show(itemId)
        .then((res) => {
          if (res) this.currentItem = res.data.data
        })
      this.toggleModal()
    },

    category: item => item.title + ' (' + item.catalog_title + ')'
  }
};
</script>

